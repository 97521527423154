import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getTopNString } from "./utils.js"

export default props => {

  const q = useStaticQuery(graphql`
    query DrugDeaths {
      allOcmeDrugDeathsCsv {
        nodes {
          Geo
          Region
          Any_OpioidRt
        }
      }
    }
  `)

  const townData = q.allOcmeDrugDeathsCsv.nodes.filter(
    x => x.Region === props.region
  );

  return (
    <section className="cf mv5">

      <div className="w-100 w-third-l fl-l f4 pb3 pr3-l">

        <h3 className="f3 mt0">Drug-Related Deaths</h3>

        <p className="mt0 measure-narrow">
          Connecticut's Office of the Chief Medical Examiner keeps track of
          drug-related deaths in the state. The map shows drug-related deaths by town in 2021,
          by drug type. Deaths are aggregated by town of <i>injury</i> of the decedent, which can be
          different from the residence place.
        </p>

        <p className="measure-narrow">
          In {props.regionName}, the highest death rates per 100,000 residents from any opioid
          are recorded in {getTopNString(townData, {prop: 'Any_OpioidRt', n: 3, suffix: '', skip: 0})}.
        </p>

        <p className="w-80 pa3 f5 br2 ba bw1 b--washed-red black-80">
          Xylazine, a large animal veterinary tranquilizer, was present in
          71 accidental drug deaths in 2019 statewide, and rose to 140 in 2020
          and 301 in 2021.
        </p>

        <p className="measure f6 black-50">
          When sorting by drug type, it should be noted that deaths may not be exclusive of other substances.
          Values are normalized per 100,000 residents for a fairer comparison.
          Note that towns with few drug-related deaths may
          have surprisingly high death rates per capita due to small town populations.
          Hover over towns to see number and population-adjusted death rates.
        </p>

        <p className="measure f6 black-50">
          *Prescription opioids include oxycodone, oxymorphone,
          hydrocodone, hydromorphone, and tramadol.
        </p>
      </div>

      <div className="w-100 w-two-thirds-l fl-l">
        <iframe
          title="Opioid-Related Death Rates"
          className="w-100 bw0"
          style={{'height': '500px'}}
          src={"https://ct-data-collaborative.github.io/cpes-data-processing/maps/ocme-drug-deaths/" + props.regionNum + '.html'}
        ></iframe>

        <p className="f6 i">Source: CT OCME, 2021; population data by DPH (2021)</p>
      </div>

    </section>
  )
}