import React, { useState } from "react"
import { Bar, HorizontalBar } from "react-chartjs-2"
import { graphql, useStaticQuery } from "gatsby"
import { getTopNString } from "./utils.js"

export default props => {
 
  const region = props.region;
  const regionName = props.regionName;

  const q = useStaticQuery(graphql`
    query PopAge {
      allAcsPopulationByAgeCsv {
        nodes {
          Geo
          Under18
          _18to24
          _25to44
          _45to64
          _65Over
          Under18Percent
          _18to24Percent
          _25to44Percent
          _45to64Percent
          _65OverPercent
          Under18Moe
          _18to24Moe
          _25to44Moe
          _45to64Moe
          _65OverMoe
          Total
        }
      }
    }
  `)

  // Get one row (region data) and convert all values to integers
  const regionData = q.allAcsPopulationByAgeCsv.nodes.filter(x => region === x.Geo)[0]
  for (var key in regionData) {
    regionData[key] = Number(regionData[key]) || regionData[key]
  }

  // Get town data and calculate % elderly and % youth for town to get min/max
  const townData = q.allAcsPopulationByAgeCsv.nodes.filter(x => props.towns.includes(x.Geo))

  const [townDataSorted, setTownDataSorted] = useState(
    townData.slice(0).sort((a, b) => b.Under18Percent - a.Under18Percent)
  )
  const [firstDataset, setFirstDataset] = useState(1)

  // Returns a sort button for Composition charts
  // x -- variable name
  // v -- text value
  // n -- order of dataset in Chartjs datasets
  const getSortButton = function(x, v, n) {
    return <input
      type="button"
      onClick={() => {
        setTownDataSorted(townData.slice(0).sort((a, b) => b[x] - a[x]));
        setFirstDataset(n)
      }}
      value={v}
      className={"bw1 ba pa1 dim pointer b--navy "
        + (n === firstDataset ? "bg-navy near-white" : "bg-near-white b--navy")}
    />
  }

  const getPercent = function(x) {
    return (x / regionData.Total * 100).toFixed(1)
  }

  return (
    <section className="cf">

      <div className="w-100 w-50-l fl f4 pb3 pr3">
        <p className="mt0">
        {regionData.Total.toLocaleString()} people live in {regionName} region.
        Of those, {getPercent(regionData.Under18)}% are the youth aged 0–17,
        and {getPercent(regionData._65Over)}% are the elderly (aged 65+).
        </p>

        <p>
          Towns with the <b>highest share of young people </b>
          are { getTopNString(townData, { prop: 'Under18Percent', n: 4, suffix: '%' }) }.
        </p>

        <p>
          { getTopNString(townData, {prop: '_65OverPercent', n: 4, suffix: '%'}) } have
          <b> the highest proportion of the elderly</b> (those aged 65 and above) in {regionName}.
        </p>
      </div>
      
      <div className="w-100 w-50-l fl">
        <Bar height={100} data={
          {
            labels: ['Under 18', '18 to 24', '25 to 44', '45 to 65', '65+'],
            datasets: [{
              data: [regionData.Under18, regionData._18to24, regionData._25to44, regionData._45to64, regionData._65Over],
              backgroundColor: '#100154'
            }]
          }
        } options={
          {
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                { 
                  display: true,
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  },
                }
              ],
              yAxes: [
                {
                  gridLines: {
                    display: true,
                    drawBorder: false,
                  },
                  ticks: {
                    userCallback: function(value, index, values) {
                      return value.toLocaleString()
                    },
                    beginAtZero: true,
                  }
                }
              ]
            },
            tooltips: {
              displayColors: false,
              callbacks: {
                label: function(tooltipItem, data) {
                  return parseInt(tooltipItem.value).toLocaleString() + ' people'
                },
              }
            },
            plugins: {
              datalabels: {
                display: true,
                align: 'bottom',
                anchor: 'end',
                color: 'white',
                formatter: function(value) {
                  return getPercent(value) + '%'
                }
              }
            }
          }
        } />

        <p className="f6 i">Source: 2021 American Community Survey, 5-year estimates</p>
      </div>

      <div className="fl w-100 bg-near-white mt3 pa3">
        <h3 className="f3"> Age Composition by Town </h3>

        <p className="f4">
          Sort towns {getSortButton('Geo', 'alphabetically', 0)}, 
          by percent {getSortButton('Under18Percent', 'under 18', 1)},
          {getSortButton('_18to24Percent', '18 to 24', 2)},
          {getSortButton('_25to44Percent', '25 to 44', 3)},
          {getSortButton('_45to64Percent', '45 to 64', 4)},
          or {getSortButton('_65OverPercent', '65 and over', 5)}.
        </p>

        <div className="relative">
          <HorizontalBar height={townDataSorted.length * 30}
            data={
              {
                labels: townDataSorted.map(x => x.Geo),
                datasets: [
                  {
                    label: 'Under 18',
                    data: townDataSorted.map(x => x.Under18Percent),
                    absoluteValues: townDataSorted.map(x => x.Under18),
                    backgroundColor: '#d0d1e6',
                    order: firstDataset === 1 ? -1 : 0 
                  },
                  {
                    label: '18 to 24',
                    data: townDataSorted.map(x => x._18to24Percent),
                    absoluteValues: townDataSorted.map(x => x._18to24),
                    backgroundColor: '#a6bddb',
                    order: firstDataset === 2 ? -1 : 0 
                  },
                  {
                    label: '25 to 44',
                    data: townDataSorted.map(x => x._25to44Percent),
                    absoluteValues: townDataSorted.map(x => x._25to44),
                    backgroundColor: '#74a9cf',
                    order: firstDataset === 3 ? -1 : 0 
                  },
                  {
                    label: '45 to 65',
                    data: townDataSorted.map(x => x._45to64Percent),
                    absoluteValues: townDataSorted.map(x => x._45to64),
                    backgroundColor: '#2b8cbe',
                    order: firstDataset === 4 ? -1 : 0 
                  },
                  {
                    label: '65+',
                    data: townDataSorted.map(x => x._65OverPercent),
                    absoluteValues: townDataSorted.map(x => x._65Over),
                    backgroundColor: '#045a8d',
                    order: firstDataset === 5 ? -1 : 0 
                  }
                ]
              }
            }

            options={{
              scales: {
                xAxes: [{
                  stacked: true,
                  ticks: {
                    max: 100,
                  }
                }],
                yAxes: [{
                  stacked: true,
                  max: 100,
                }]
              },
              tooltips: {
                displayColors: true,
                callbacks: {
                  label: function(tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].label + ': '
                      +  parseInt(data.datasets[tooltipItem.datasetIndex].absoluteValues[tooltipItem.index]).toLocaleString()
                      + ' people (' + tooltipItem.value + '%)'
                  },
                }
              },
              plugins: {
                datalabels: {
                  display: true,
                  color: function(ctx) {
                    return ctx.datasetIndex < 2 ? 'gray' : 'white';
                  },
                  formatter: function(value) {
                    return Math.round(value) + '%'
                  }
                }
              },
              maintainAspectRatio: false
            }}
          />

        </div>

        <p className="f6 i">Source: 2021 American Community Survey, 5-year estimates</p>

      </div>

    </section>
  )
}