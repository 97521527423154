import React from "react"
import { getBRFSSBarChart } from "./utils"

export default props => {

  const charts = [
    {
      "domain": "Total",
      "labels": ["Total"],
      "values": [14.4],
      "ci": [[13.4, 15.4]]
    },
    {
      "domain": "Age",
      "labels": ["18-34 Years Old", "35-54 Years Old", "55 and Over"],
      "values": [18.1, 12.4, 13.9],
      "ci": [[15.4, 20.8], [10.9, 13.9], [12.7, 15.0]]  // confidence intervals
    },
    {
      "domain": "Sex",
      "labels": ["Male", "Female"],
      "values": [10.8, 17.8],
      "ci": [[9.5, 12.1], [16.3, 19.3]]
    },
    {
      "domain": "Race/Ethnicity",
      "labels": ["White Non-Hisp", "Black Non-Hisp", "Hispanic", 'Other Non-Hisp'],
      "values": [16.2, 8.8, 12.0, 12.2],
      "ci": [[15.0, 17.5], [6.2, 11.4], [9.3, 14.7], [8.3, 16.1]]
    },
    {
      "domain": "Income",
      "labels": ["<$35,000", "$35,000-$74,999", "$75,000+"],
      "values": [22.4, 13.2, 11.1],
      "ci": [[19.8, 24.9], [11.1, 15.2], [9.7, 12.6]]
    },
    {
      "domain": "Insurance",
      "labels": ["Insured", "Not Insured"],
      "values": [15.1, 7.9],
      "ci": [[14.0, 16.1], [5.4, 10.5]]
    },
    {
      "domain": "Disability",
      "labels": ["Disabled", "Non-disabled"],
      "values": [33.0, 9.5],
      "ci": [[30.0, 35.9], [8.6, 10.5]]
    },
    {
      "domain": "Education",
      "labels": ["High School or Less", "More than High School"],
      "values": [15.4, 14.0],
      "ci": [[13.5, 17.3], [12.8, 15.1]]
    },
  ]

  return (
    <section className="mt5 mb4 cf">
      <h3 className="f3">Depressive Disorder</h3>

      <div className="w-100 w-third-l cf fl-l">
        <p className="f4 mt0 measure">
          The bar chart shows the proportion of adults in Connecticut who reported
          ever being told by a doctor that they had a depressive disorder including
          major or minor depression, or dysthymia, according to the
          2019 Behavioral Risk Factor Survey.
        </p>

        <p className="f4 measure">
          The highest value for each breakdown is highlighted.
          According to the survey, younger people, females, people on lower incomes,
          and people with a disability were told they had a depressive disorder
          more often.
        </p>

        <p className="f6 black-50 mt0 measure">
          Hover over bars to see 95% confidence intervals, which can be wide for some
          demographic groups. Source: 2019 Connecticut Behavioral Risk Factor
          Survey. <a href="https://portal.ct.gov/-/media/Departments-and-Agencies/DPH/BRFSS/CTBRFSS2019_Summary_Tables.pdf" target="_blank" rel="noopener noreferrer" className="link dim green">
            Explore other summary tables
          </a>.
        </p>
      </div>

      <div className="w-100 w-two-thirds-l cf fl-l">
        {
          charts.map(function(chart) {
            return getBRFSSBarChart( chart.domain, chart.labels, chart.values, chart.ci, 'rgba(89,156,61,1)' )
          })
        }
      </div>
    </section>
  )
}