import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Bar } from "react-chartjs-2"

export default props => {

  const q = useStaticQuery(graphql`
    query NsduhRisk {
      allNsduhRiskCsv {
        nodes {
          Geo
          Substance
          _12_17_Estimate
          _18_25_Estimate
          _26_or_Older_Estimate
        }
      }
    }
  `)

  const regionData = q.allNsduhRiskCsv.nodes.filter(
    x => x.Geo === props.regionName
  );

  const stateData = q.allNsduhRiskCsv.nodes.filter(
    x => x.Geo === 'Connecticut'
  );

  const labels = ['_12_17_Estimate', '_18_25_Estimate', '_26_or_Older_Estimate'];
  const labelsFormatted = ['12 to 17', '18 to 25', '26 and Older']

  const sub2color = {
    'Alcohol': 'rgba(248,187,45,1)',
    'Cocaine': '#666666',
    'Heroin': '#999999',
    'Cigarette': '#cccccc',
    'Marijuana': 'rgba(89,156,61,1)'
  }

  const stateColor = 'red';

  const datasets = regionData.map(function(sub) {
    return {
      label: sub.Substance,
      stack: sub.Substance,
      backgroundColor: sub2color[sub.Substance] || '#cccccc',
      order: 2,
      data: labels.map(function(ageGroup) { return sub[ageGroup] })
    }
  });

  const datasetsState = stateData.map(function(sub) {
    return {
      label: sub.Substance + 'State',
      stack: sub.Substance,
      order: 1,
      backgroundColor: stateColor,
      data: labels.map(function(ageGroup) {
        return [ parseFloat(sub[ageGroup]) - 0.15, parseFloat(sub[ageGroup]) + 0.15]
      })
    }
  });

  return (
      <div className="relative">
        <Bar height={400} data={
          {
            labels: labelsFormatted,
            datasets: datasets.concat(datasetsState)
          }
        } options={
          {
            legend: {
              display: true,
              labels: {
                filter: function(item, chart) {
                  return !item.text.includes('State')
                }
              }
            },
            scales: {
              xAxes: [
                { 
                  display: true,
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  },
                }
              ],
              yAxes: [
                {
                  stacked: false,
                  gridLines: {
                    display: true,
                    drawBorder: false,
                  },
                  ticks: {
                    userCallback: function(value, index, values) {
                      return value.toLocaleString()
                    },
                    beginAtZero: true,
                  }
                }
              ]
            },
            tooltips: {
              displayColors: true,
              callbacks: {
                
                label: function(tooltipItem, data) {
                  return tooltipItem.value.indexOf('[') === 0
                    ? (parseFloat(tooltipItem.value.split(',')[0].substr(1)) + 0.15).toFixed(1) + '%'
                    : data.datasets[tooltipItem.datasetIndex].label
                      + ' Perception of Great Risk: ' + tooltipItem.value + '%'
                },
              }
            },
            plugins: {
              datalabels: {
                display: true,
                align: 'bottom',
                anchor: 'end',
                color: 'white',
                formatter: function(value, context) {
                  // Do not show labels for state values
                  return context.dataset.backgroundColor === stateColor
                    ? ''
                    : Math.round(value) + (context.datasetIndex === 0 ? '%' : '');
                }
              }
            },
            maintainAspectRatio: false
          }
        } />

        
      </div>
  )
}