import React from "react"

export default props => {

  const reg = parseInt(props.regionNum);

  const tableStyle = `
    #substance-misuse-table td,
    #substance-misuse-table th {
      padding: 10px;
    }

    #substance-misuse-table td:nth-child(${reg}),
    #substance-misuse-table th:nth-child(${reg}) {
      background-color: #ddd;
    }
  `

  return (
    <section className="cf mv5">

      <div className="w-100 fl-l f4 pb3 pr3-l">

        <h3 className="f3 mt0">Substance Misuse / Addiction Priorities</h3>

        <table className="w-100 tl collapse" id="substance-misuse-table">
          <thead>
            <tr>
              <th>Region 1</th>
              <th>Region 2</th>
              <th>Region 3</th>
              <th>Region 4</th>
              <th>Region 5</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="pv2"><span className="dib br-100 w2 tc ba">1</span> <span className="bg-yellow ph1">Alcohol</span></td>
              <td><span className="dib br-100 w2 tc ba">1</span> <span className="bg-light-purple ph1">Heroin/Fentanyl</span></td>
              <td><span className="dib br-100 w2 tc ba">1/2</span> <span className="bg-yellow ph1">Alcohol</span></td>
              <td><span className="dib br-100 w2 tc ba">1/2</span> <span className="bg-yellow ph1">Alcohol</span></td>
              <td><span className="dib br-100 w2 tc ba">1</span> <span className="bg-yellow ph1">Alcohol</span></td>
            </tr>
            <tr>
              <td className="pv2"><span className="dib br-100 w2 tc ba">2</span> <span className="bg-green ph1">Marijuana</span></td>
              <td><span className="dib br-100 w2 tc ba">2</span> <span className="bg-orange ph1">Prescription Drugs</span></td>
              <td><span className="dib br-100 w2 tc ba">1/2</span> <span className="bg-light-purple ph1">Heroin/Fentanyl</span></td>
              <td><span className="dib br-100 w2 tc ba">1/2</span> <span className="bg-light-purple ph1">Heroin/Fentanyl</span></td>
              <td><span className="dib br-100 w2 tc ba">2</span> <span className="bg-light-purple ph1">Heroin/Fentanyl</span></td>
            </tr>
            <tr>
              <td className="pv2"><span className="dib br-100 w2 tc ba">3/4</span> <span className="bg-moon-gray ph1">Tobacco</span></td>
              <td><span className="dib br-100 w2 tc ba">3</span> <span className="white bg-blue ph1">ENDS</span></td>
              <td><span className="dib br-100 w2 tc ba">3/4</span> <span className="bg-orange ph1">Prescription Drugs</span></td>
              <td><span className="dib br-100 w2 tc ba">3</span> <span className="bg-green ph1">Marijuana</span></td>
              <td><span className="dib br-100 w2 tc ba">3</span> <span className="white bg-blue ph1">ENDS</span></td>
            </tr>
            <tr>
              <td className="pv2"><span className="dib br-100 w2 tc ba">3/4</span> <span className="white bg-blue ph1">ENDS</span></td>
              <td><span className="dib br-100 w2 tc ba">4</span> <span className="bg-yellow ph1">Alcohol</span></td>
              <td><span className="dib br-100 w2 tc ba">3/4</span> <span className="white bg-blue">ENDS</span></td>
              <td><span className="dib br-100 w2 tc ba">4</span> <span className="bg-orange ph1">Prescription Drugs</span></td>
              <td><span className="dib br-100 w2 tc ba">4/5</span> <span className="bg-green ph1">Marijuana</span></td>
            </tr>
            <tr>
              <td className="pv2"><span className="dib br-100 w2 tc ba">5</span> <span className="bg-orange">Prescription Drugs</span></td>
              <td><span className="dib br-100 w2 tc ba">5</span> <span className="bg-green ph1">Marijuana</span></td>
              <td><span className="dib br-100 w2 tc ba">5</span> <span className="bg-green ph1">Marijuana</span></td>
              <td><span className="dib br-100 w2 tc ba">5</span> <span className="white bg-blue ph1">ENDS</span></td>
              <td><span className="dib br-100 w2 tc ba">4/5</span> <span className="bg-orange ph1">Prescription Drugs</span></td>
            </tr>
            <tr>
              <td className="pv2"><span className="dib br-100 w2 tc ba">6</span> <span className="bg-light-purple ph1">Heroin</span></td>
              <td><span className="dib br-100 w2 tc ba">6</span> <span className="bg-moon-gray ph1">Tobacco</span></td>
              <td><span className="dib br-100 w2 tc ba">6/7</span> <span className="bg-moon-gray ph1">Tobacco</span></td>
              <td><span className="dib br-100 w2 tc ba">6/7</span> <span className="bg-moon-gray ph1">Tobacco</span></td>
              <td><span className="dib br-100 w2 tc ba">6</span> <span className="bg-moon-gray ph1">Tobacco</span></td>
            </tr>
            <tr>
              <td className="pv2"><span className="dib br-100 w2 tc ba">7</span> <span className="white bg-dark-red ph1">Problem Gambling</span></td>
              <td><span className="dib br-100 w2 tc ba">7/8</span> <span className="bg-silver ph1">Cocaine</span></td>
              <td><span className="dib br-100 w2 tc ba">6/7</span> <span className="white bg-dark-red ph1">Problem Gambling</span></td>
              <td><span className="dib br-100 w2 tc ba">6/7</span> <span className="bg-silver ph1">Cocaine</span></td>
              <td><span className="dib br-100 w2 tc ba">7</span> <span className="bg-silver ph1">Cocaine</span></td>
            </tr>
            <tr>
              <td className="pv2"><span className="dib br-100 w2 tc ba">8</span> <span className="bg-silver ph1">Cocaine</span></td>
              <td><span className="dib br-100 w2 tc ba">7/8</span> <span className="white bg-dark-red ph1">Problem Gambling</span></td>
              <td><span className="dib br-100 w2 tc ba">8</span> <span className="bg-silver ph1">Cocaine</span></td>
              <td><span className="dib br-100 w2 tc ba">8</span> <span className="white bg-dark-red ph1">Problem Gambling</span></td>
              <td><span className="dib br-100 w2 tc ba">8</span> <span className="white bg-dark-red ph1">Problem Gambling</span></td>
            </tr>
          </tbody>
        </table>
        

        <p className="f6 i">Source: DMHAS Regional Prioritization and Reports:
          Process and Selected Results, 2021</p>
      </div>

      <style>{tableStyle}</style>

    </section>
  )
}