import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getTopNString } from "./utils.js"
import _ from "lodash"

export default props => {

  const q = useStaticQuery(graphql`
    query DUICrashes {
      allDuiCrashesCsv {
        nodes {
          Geo
          CrashesDui
          CrashesAll
          CrashesDuiPerc
          Region
        }
      }
    }
  `)

  const townData = q.allDuiCrashesCsv.nodes.filter(x => x.Region === props.region)

  const min = _.minBy(townData, (o) => parseFloat(o.CrashesDuiPerc))
  const max = _.maxBy(townData, (o) => parseFloat(o.CrashesDuiPerc))

  const ctDuiPerc = parseFloat(q.allDuiCrashesCsv.nodes.filter(x => x.Geo === 'Connecticut')[0].CrashesDuiPerc)
  const lessThanCt = townData.filter(o => parseFloat(o.CrashesDuiPerc) < ctDuiPerc).length
  const moreThanCt = townData.filter(o => parseFloat(o.CrashesDuiPerc) > ctDuiPerc).length

  return (
    <section className="cf mv5">

      <div className="w-100 w-third-l fl-l f4 pb3 pr3-l">

        <h3 className="f3 mt0">DUI-Related Motor Vehicle Crashes</h3>

        <p className="mt0 measure-narrow">
          Percentage of car crashes that involved a <b>driver under the influence of alcohol, drugs, or medication</b> (DUI) in {props.regionName} in 2020
          ranged from { min.CrashesDuiPerc }% ({parseInt(min.CrashesDui)} of {parseInt(min.CrashesAll)} crashes) in {min.Geo} to
          {} { max.CrashesDuiPerc }% ({parseInt(max.CrashesDui)} of {parseInt(max.CrashesAll)})
          in {max.Geo}, compared to { parseFloat(ctDuiPerc) }% overall in Connecticut.
        </p>

        <p className="measure-narrow">
          In {lessThanCt} of {townData.length} towns in the region, DUI crashes are less frequent than in the state on average,
          and in {moreThanCt} towns such crashes are more frequent.
        </p>

        <p className="measure-narrow">
          Towns with the highest share of DUI crashes
          in {props.regionName} are {getTopNString(townData, {prop: 'CrashesDuiPerc', n: 3, suffix: '%', skip: 0})}.
        </p>

        <p className="measure-narrow f5 black-50">
          Hover over towns to see total number of crashes, and number and percentage of DUI-related crashes.
        </p>
      </div>

      <div className="w-100 w-two-thirds-l fl-l">
        <iframe
          title="% DUI Crashes"
          className="w-100 bw0"
          style={{'height': '500px'}}
          src={"https://ct-data-collaborative.github.io/cpes-data-processing/maps/dui-crashes/" + props.regionNum + '.html'}
        ></iframe>

        <p className="f6 i">Source: Connecticut Crash Data Repository, 2021</p>
      </div>

    </section>
  )
}