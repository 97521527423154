import React, { useState } from "react"
import { HorizontalBar } from "react-chartjs-2"
import { graphql, useStaticQuery } from "gatsby"
import { getTopNString } from "./utils.js"

export default props => {
 
  const region = props.region;
  const regionName = props.regionName;

  const q = useStaticQuery(graphql`
    query PopRace {
      allAcsPopulationByRaceCsv {
        nodes {
          Geo
          Total
          White
          WhitePercent
          Black
          BlackPercent
          Asian
          AsianPercent
          HispanicLatino
          HispanicLatinoPercent
          AmericanIndianAlaskaNative
          NativeHawaiian
          OtherAlone
          TwoOrMore
        }
      }
    }
  `)

  // Get one row (region data) and convert all values to integers
  const regionData = q.allAcsPopulationByRaceCsv.nodes.filter(x => region === x.Geo)[0]
  for (var key in regionData) {
    regionData[key] = Number(regionData[key]) || regionData[key]
  }

  const townData = q.allAcsPopulationByRaceCsv.nodes.filter(x => props.towns.includes(x.Geo))

  const [townDataSorted, setTownDataSorted] = useState(
    townData.slice(0).sort((a, b) => b.WhitePercent - a.WhitePercent)
  )
  const [firstDataset, setFirstDataset] = useState(1)

  // Returns a sort button for Composition charts
  // x -- variable name
  // v -- text value
  // n -- order of dataset in Chartjs datasets
  const getSortButton = function(x, v, n) {
    return <input
      type="button"
      onClick={() => {
        setTownDataSorted(townData.slice(0).sort((a, b) => b[x] - a[x]));
        setFirstDataset(n)
      }}
      value={v}
      className={"bw1 ba pa1 dim pointer b--navy "
        + (n === firstDataset ? "bg-navy near-white" : "bg-near-white b--navy")}
    />
  }

  const getPercent = function(x) {
    return (x / regionData.Total * 100).toFixed(1)
  }

  return (
    <section className="cf">

      <div className="w-100 w-50-l fl pb3 pr3">
        <p className="mt0 f4">
          In {regionName}, {regionData.WhitePercent}% of the population self-identify
          as White non-Hispanic, {regionData.BlackPercent}% as Black non-Hispanic,
          and {regionData.AsianPercent}% as Asian non-Hispanic. {' '}
          {regionData.HispanicLatino.toLocaleString()} people, or {regionData.HispanicLatinoPercent}%,
          self-identify as Hispanic or Latino of any race.
        </p>

        <p>
          <i>Other</i> category in the bar charts includes American Indian /
          Alaska Native population ({regionData.AmericanIndianAlaskaNative.toLocaleString()} people),
          Native Hawaiian population ({regionData.NativeHawaiian.toLocaleString()} people),
          those identifying as 'Other Race Alone' ({regionData.OtherAlone.toLocaleString()} people)
          and 'Two or More Races' ({regionData.TwoOrMore.toLocaleString()} people), all four are non-Hispanic.
        </p>
      </div>
      
      <div className="w-100 w-50-l fl">
        <HorizontalBar height={100} data={
          {
            labels: [
              'White',
              'Black',
              'Hispanic or Latino',
              'Asian',
              'Other'
            ],
            datasets: [{
              data: [
                regionData.White,
                regionData.Black,
                regionData.HispanicLatino,
                regionData.Asian,
                regionData.AmericanIndianAlaskaNative
                  + regionData.NativeHawaiian
                  + regionData.OtherAlone
                  + regionData.TwoOrMore
              ],
              backgroundColor: '#589b3c'
            }]
          }
        } options={
          {
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                { 
                  display: true,
                  gridLines: {
                    display: true,
                    drawBorder: false,
                  },
                  ticks: {
                    userCallback: function(value, index, values) {
                      return value.toLocaleString()
                    },
                    beginAtZero: true,
                    maxTicksLimit: 10
                  }
                }
              ],
              yAxes: [
                {
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  },
                  ticks: {
                    beginAtZero: true,
                  }
                }
              ]
            },
            tooltips: {
              displayColors: false,
              callbacks: {
                label: function(tooltipItem, data) {
                  return parseInt(tooltipItem.value).toLocaleString() + ' people'
                },
              }
            },
            plugins: {
              datalabels: {
                display: true,
                align: 'right',
                anchor: 'end',
                color: 'gray',
                formatter: function(value) {
                  return Math.round( getPercent(value) ) + '%'
                }
              }
            }
          }
        } />

        <p className="f6 i">Source: 2021 American Community Survey, 5-year estimates</p>
      </div>

      <div className="fl w-100 bg-near-white mt3 pa3">
        <h3 className="f3"> Racial Composition by Town </h3>

        <p className="f4 measure-wide">
          Towns with the highest proportion of <b>Hispanic</b> population
          are { getTopNString(townData, {prop: 'HispanicLatinoPercent', n: 5, suffix: '%'}) }.
        </p>

        <p className="f4 measure-wide">
          The share of <b>Black non-Hispanic</b> population is highest in 
          { getTopNString(townData, {prop: 'BlackPercent', n: 4} ) }; <b>Asian non-Hispanic</b>&mdash;
          in { getTopNString(townData, {prop: 'AsianPercent', n: 4, suffix: '%'} ) }.
        </p>

        <p className="f4">
          Sort towns {getSortButton('Geo', 'alphabetically', 0)},
          by percent {getSortButton('WhitePercent', 'white', 1)},
          {getSortButton('BlackPercent', 'black', 2)},
          {getSortButton('HispanicLatinoPercent', 'Hispanic', 3)},
          or {getSortButton('AsianPercent', 'Asian', 4)}.
        </p>

        <div className="relative">
          <HorizontalBar
            height={townData.length * 30}
            data={
              {
                labels: townDataSorted.map(x => x.Geo),
                datasets: [
                  {
                    label: 'White',
                    data: townDataSorted.map(x => x.WhitePercent),
                    absoluteValues: townDataSorted.map(x => x.White),
                    backgroundColor: '#c7e9c0',
                    order: firstDataset === 1 ? -1 : 0
                  },
                  {
                    label: 'Black',
                    data: townDataSorted.map(x => x.BlackPercent),
                    absoluteValues: townDataSorted.map(x => x.Black),
                    backgroundColor: '#a1d99b',
                    order: firstDataset === 2 ? -1 : 0
                  },
                  {
                    label: 'Hispanic/Latino',
                    data: townDataSorted.map(x => x.HispanicLatinoPercent),
                    absoluteValues: townDataSorted.map(x => x.HispanicLatino),
                    backgroundColor: '#74c476',
                    order: firstDataset === 3 ? -1 : 0
                  },
                  {
                    label: 'Asian',
                    data: townDataSorted.map(x => x.AsianPercent),
                    absoluteValues: townDataSorted.map(x => x.Asian),
                    backgroundColor: '#31a354',
                    order: firstDataset === 4 ? -1 : 0
                  },
                  {
                    label: 'Other',
                    data: townDataSorted.map(x => parseFloat(100 - x.WhitePercent - x.BlackPercent - x.HispanicLatinoPercent - x.AsianPercent).toFixed(1)),
                    absoluteValues: townDataSorted.map(x => parseInt(x.NativeHawaiian) + parseInt(x.OtherAlone) + parseInt(x.TwoOrMore) + parseInt(x.AmericanIndianAlaskaNative)),
                    backgroundColor: '#006d2c',
                  }
                ]
              }
            }

            options={{
              scales: {
                xAxes: [{
                  stacked: true,
                  ticks: {
                    max: 100
                  }
                }],
                yAxes: [{
                  stacked: true,
                  max: 100,
                }]
              },
              tooltips: {
                displayColors: true,
                callbacks: {
                  label: function(tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].label + ': '
                      +  parseInt(data.datasets[tooltipItem.datasetIndex].absoluteValues[tooltipItem.index]).toLocaleString()
                      + ' people (' + tooltipItem.value + '%)'
                  },
                }
              },
              plugins: {
                datalabels: {
                  display: true,
                  color: function(ctx) {
                    return ctx.datasetIndex < 2 ? 'gray' : 'white';
                  },
                  formatter: function(value) {
                    return value > 2 ? Math.round(value) + '%' : ''
                  }
                }
              },
              maintainAspectRatio: false,
            }}
          />
          </div>

          <p className="f6 i">Source: 2021 American Community Survey, 5-year estimates</p>

      </div>

    </section>
  )
}