import React from "react"
import { HorizontalBar } from "react-chartjs-2"

export default props => {

  const region = props.regionName;

  const labels = ['Southwest CT', 'South Central CT', 'Eastern CT',
    'North Central CT', 'Northwestern CT', 'Connecticut'];

  return (
      <div className="relative">
        <HorizontalBar height={100} data={
          {
            labels: labels,
            datasets: [{
              data: [6.13, 5.93, 6.11, 6.25, 5.80, 6.06], // Alcohol
              backgroundColor: labels.map(function(l) {
                return region === l ? 'rgba(248,187,45,1)' : 'rgba(248,187,45,0.5)'
              }),
              label: 'Alcohol'
            }, {
              data: [7.69, 8.44, 8.86, 8.69, 8.05, 8.35], // Substance
              backgroundColor: labels.map(function(l) {
                return region === l ? 'rgba(89,156,61,1)' : 'rgba(89,156,61,0.5)'
              }),
              label: 'Substance'
            }]
          }
        } options={
          {
            legend: {
              display: true,
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: true,
                    drawBorder: false,
                  },
                  ticks: {
                    userCallback: function(value, index, values) {
                      return value.toLocaleString()
                    },
                    beginAtZero: true,
                  }
                }
              ],
              yAxes: [
                { 
                  display: true,
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  },
                }
              ]
            },
            tooltips: {
              displayColors: true,
              callbacks: {
                
                label: function(tooltipItem, data) {
                  return data.datasets[tooltipItem.datasetIndex].label
                    + ' Use Disorder: ' + tooltipItem.value + '%'
                },
              }
            },
            plugins: {
              datalabels: {
                display: true,
                align: 'left',
                anchor: 'end',
                color: 'white',
                formatter: function(value, context) {
                  // If current region (opacity=1), show data labels
                  return context.dataset.backgroundColor[context.dataIndex].indexOf(",1)") > 0
                    ? value + '%' : '';
                }
              }
            },
            //maintainAspectRatio: false
          }
        } />

        <p className="f6 i">Source: 2018<span className="light-red">*</span> National Survey on Drug Use and Health (NSDUH)</p>
      </div>
  )
}