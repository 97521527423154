import React from "react"
import { Bar } from "react-chartjs-2"

export default props => {

  const region = props.regionName;

  const labels = ['Southwest CT', 'South Central CT', 'Eastern CT',
    'North Central CT', 'Northwestern CT'];

  return (
      <div className="relative">
        <Bar height={400} data={
          {
            labels: labels,
            datasets: [{
              data: [249,499,287,546,337], // N suicides
              backgroundColor: labels.map(function(l) {
                return region === l ? 'rgba(100,100,100,1)' : 'rgba(100,100,100,0.5)'
              }),
              label: 'Suicides',
              yAxisID: 'count'
            },

            {
              data: [7.0, 11.9, 13.9, 10.9, 11.1], // Suicide Rates
              backgroundColor: labels.map(function(l) {
                return region === l ? 'rgba(242,121,70,1)' : 'rgba(242,121,70,0.5)'
              }),
              label: 'Suicide Rate',
              yAxisID: 'rate'
            }
          ]
          }
        } options={
          {
            legend: {
              display: true,
            },
            scales: {
              xAxes: [
                { 
                  display: true,
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  },
                }
              ],
              yAxes: [
                {
                  id: 'count',
                  position: 'left',
                  scaleLabel: {
                    display: true,
                    labelString: 'Suicides',
                  },
                  gridLines: {
                    display: true,
                    drawBorder: false,
                  },
                  ticks: {
                    userCallback: function(value, index, values) {
                      return value.toLocaleString()
                    },
                    beginAtZero: true,
                  }
                },
                {
                  id: 'rate',
                  position: 'right',
                  scaleLabel: {
                    display: true,
                    labelString: 'Suicide Rate',
                  },
                  gridLines: {
                    display: true,
                    drawBorder: false,
                  },
                  ticks: {
                    userCallback: function(value, index, values) {
                      return value.toLocaleString()
                    },
                    beginAtZero: true,
                  }
                }
              ]
            },
            plugins: {
              datalabels: {
                display: true,
                align: 'bottom',
                anchor: 'end',
                color: 'white',
                formatter: function(value, context) {
                  // Round rates to .1, counts to integer
                  return parseFloat(value).toFixed(
                    context.datasetIndex === 1 ? 1 : 0
                  );
                }
              }
            },
            maintainAspectRatio: false
          }
        } />
        
      </div>
  )
}