import React from "react"
import { Bar } from "react-chartjs-2"

export default props => {

  const region = props.regionName;

  const labels = ['Southwest CT', 'South Central CT', 'Eastern CT',
    'North Central CT', 'Northwestern CT', 'Connecticut'];

  return (
      <div className="relative">
        <Bar height={300} data={
          {
            labels: labels,
            datasets: [{
              data: [15.76, 17.61, 17.64, 17.09, 17.61, 17.11], // MH
              backgroundColor: labels.map(function(l) {
                return region === l ? 'rgba(114,125,131,1)' : 'rgba(114,125,131,0.5)'
              }),
              label: 'Received Mental Health Services'
            }]
          }
        } options={
          {
            legend: {
              display: true,
            },
            scales: {
              xAxes: [
                { 
                  display: true,
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  },
                }
              ],
              yAxes: [
                {
                  gridLines: {
                    display: true,
                    drawBorder: false,
                  },
                  ticks: {
                    userCallback: function(value, index, values) {
                      return value.toLocaleString()
                    },
                    beginAtZero: true,
                  }
                }
              ]
            },
            tooltips: {
              displayColors: true,
              callbacks: {
                label: function(tooltipItem, data) {
                  return data.datasets[tooltipItem.datasetIndex].label
                    + ': ' + tooltipItem.value + '%'
                }
              }
            },
            plugins: {
              datalabels: {
                display: true,
                align: 'bottom',
                anchor: 'end',
                color: 'white',
                formatter: function(value) {
                  return parseFloat(value).toFixed(1) + '%';
                }
              }
            },
            maintainAspectRatio: false
          }
        } />

      </div>
  )
}