import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getTopNString } from "./utils.js"

export default props => {

  const q = useStaticQuery(graphql`
    query DmhasAdmissions {
      allDmhasAdmissionsCsv {
        nodes {
          Geo
          Region
          MH_Only_Rt
        }
      }
    }
  `)

  const townData = q.allDmhasAdmissionsCsv.nodes.filter(
    x => x.Region === props.region
  );

  return (
    <section className="cf mv5">

      <div className="w-100 w-third-l fl-l f4 pb3 pr3-l">

        <h3 className="f3 mt0">DMHAS Treatment Admissions</h3>

        <p className="mt0 measure-narrow">
          This map shows the number of treatment admissions in DMHAS funded or operated
          programs by town in {props.regionName} in the 2020 fiscal year. Admissions
          are reported as episode counts (as many clients have multiple episodes),
          and are grouped into mental health-related or substance abuse-related, or both.
        </p>

        <p className="measure-narrow">
          In {props.regionName}, {getTopNString(townData, {prop: 'MH_Only_Rt', n: 4, suffix: '', skip: 0})} have
          the highest mental health treatment admission rates
          per 10,000 residents.
        </p>

        <p className="measure f6 black-50">
          Values are normalized per 10,000 residents for a fairer comparison.
          Note that towns with few admissions may
          have surprisingly high rates per capita due to small town population.
          Hover over towns to see number and population-adjusted admission rates.
        </p>

        <p className="measure f6 black-50">
          Episode counts represent an <i>episode of care</i> to a client –
          entailing admission, all services received, and discharge. Thus,
          an episode count is all activity that occurs within the context
          of an episode of care for a client at a particular program.
        </p>

        <p className="measure f6 black-50">
          Learn more about DMHAS treatment admissions in the <a href="https://portal.ct.gov/-/media/DMHAS/EQMI/AnnualReports/DMHAS-Annual-Statistical-Report-2021.pdf" target="_blank" rel="noopener noreferrer" className="link blue dim">2021 Annual Statistical Report</a>.
        </p>

      </div>

      <div className="w-100 w-two-thirds-l fl-l">
        <iframe
          title="DMHAS Treatment Admissions"
          className="w-100 bw0"
          style={{'height': '500px'}}
          src={"https://ct-data-collaborative.github.io/cpes-data-processing/maps/dmhas-admissions/" + props.regionNum + '.html'}
        ></iframe>

        <p className="f6 i">Source: DMHAS, SFY 2021; population data by DPH (2021)</p>
      </div>

    </section>
  )
}