import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Line } from "react-chartjs-2"

export default props => {

  const q = useStaticQuery(graphql`
    query DrugDeathsLine {
      allOcmeDrugDeathsByMonthCsv {
        nodes {
          Region
          year_month
          HeroinFentanyl
          Cocaine
          Benzodiaz
          Rx
        }
      }
    }
  `)

  const regionData = q.allOcmeDrugDeathsByMonthCsv.nodes.filter(
    x => x.Region === props.region
  );

  return (
    <section className="cf mv5">

      <h3 className="f3 mt0">Drug-Related Deaths in {props.regionName} by Month, 2020-2021</h3>

      <p className="measure f4">
        The line chart below shows recorded drug-related deaths by months in {props.RegionName} (based on <i>injury</i> town)
        for the four types of drugs: heroin or fentanyl, cocaine, benzodiaz, and prescription opioids
        (which include oxycodone, oxymorphone, hydrocodone, hydromorphone, and tramadol.)
      </p>

        <div className="relative">

          <Line
            height={350}
            data={{
              labels: regionData.map(function(x) { return x['year_month'] }),
              datasets: [{
                label: 'Heroin/Fentanyl',
                borderColor: '#7E878D',
                backgroundColor: '#7E878D',
                fill: false,
                data: regionData.map(function(x) { return x['HeroinFentanyl'] }),
                pointRadius: 2
              },{
                label: 'Cocaine',
                borderColor: '#F7C463',
                backgroundColor: '#F7C463',
                fill: false,
                data: regionData.map(function(x) { return x['Cocaine'] }),
                pointRadius: 2
              },{
                label: 'Benzodiaz',
                borderColor: '#07065B',
                backgroundColor: '#07065B',
                fill: false,
                data: regionData.map(function(x) { return x['Benzodiaz'] }),
                pointRadius: 2
              },{
                label: 'Prescription Opioids',
                borderColor: '#94C366',
                backgroundColor: '#94C366',
                fill: false,
                data: regionData.map(function(x) { return x['Rx'] }),
                pointRadius: 2
              }]
            }}
            options={{
              maintainAspectRatio: false,
              scales: {
                yAxes: [{
                  display: true,
                  gridLines: {display: true, lineWidth: 1, zeroLineWidth: 1, zeroLineColor: 'rgba(0,0,0,0.1)'},
                  ticks: {display: true, callback: function(x) {return x}, beginAtZero: false},
                }],
                xAxes: [{
                  display: true,
                  gridLines: {display: false}
                }]
              },
              legend: {
                display: true,
                onClick: null
              },
              
              tooltips: {
                intersect: false,
                mode: 'index'
              },
              
              plugins: {
                datalabels: {
                  display: false
                }
              }
            }}
          />

      </div>

      <p className="f6 i">Source: CT OCME, 2020-2021</p>

    </section>
  )
}