import React from "react"
import { HorizontalBar } from "react-chartjs-2"
import _ from "lodash"

// Returns a formatted string with `n` values
export function getTopNString (arr, options) {
  var p = options.prop || ''
  var n = options.n || 4
  var geo = options.geo || 'Geo'
  var suffix = options.suffix || ''
  var prefix = options.prefix || ''
  var skip = options.skip || 0

  var sortFunction = options.min
    ? (a, b) => parseFloat(a[p]) - parseFloat(b[p])
    : (a, b) => parseFloat(b[p]) - parseFloat(a[p])

  return arr.slice(0)
    .sort( sortFunction )
    .slice(skip, n + skip)
    .map( (x, idx) =>
      <span key={x + idx}>
        {idx === (n-1) ? ' and ' : ' '}
        {x[geo]} <span className="black-60">({prefix}{ parseFloat(x[p]).toLocaleString() }{suffix})</span>
        {idx === (n-1) ? '' : ','}        
      </span>
  )
}

export const getBRFSSBarChart = function(domain, labels, values, ci, highlightColor) {

  const max = _.max(values);

  return (
    <div className="cf" key={"brfss-" + values.join('') + '-' + domain}>
      <h5 className="fl w-20 tr f4 pr4 black-40 mt2 f5">{domain}</h5>

      <div className="fl w-80 relative bl bw1 b--black-10 mb2 pl2">
        <HorizontalBar height={20 + 18 * labels.length} data={
          {
            labels: labels,
            datasets: [{
              data: values,
              backgroundColor: values.map(function(x) {
                return (x === max && values.length > 1) ? (highlightColor || '#999999') : '#999999'
              }),
              label: null
            }]
          }
        } options={
          {
            legend: {
              display: false,
            },
            scales: {
              xAxes: [{ display: false, ticks: {beginAtZero: true}  }],
              yAxes: [{ display: true, gridLines: {display: false},
                afterFit: function(scale) { scale.width = 135 } }]
            },
            tooltips: {
              displayColors: false,
              xAlign: 'right',
              yAlign: 'center',
              callbacks: {
                title: function() {return ''},
                label: function(item) {
                  return ci[ item.index ].map(function(x) { return x + '%' }).join(' to ')
                }
              }
            },
            plugins: {
              datalabels: {
                display: true,
                align: 'left',
                anchor: 'end',
                color: 'white',
                formatter: function(value) {
                  return parseFloat(value).toFixed(1) + '%';
                }
              }
            },
            maintainAspectRatio: false
          }
        } />
      </div>
    </div>
  )
}