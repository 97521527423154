import React from "react"
import { getBRFSSBarChart } from "./utils"

export default props => {

  const charts = [
    {
      "domain": "Total",
      "labels": ["Total"],
      "values": [11.3],
      "ci": [[10.4, 12.3]]
    },
    {
      "domain": "Age",
      "labels": ["18-34 Years Old", "35-54 Years Old", "55 and Over"],
      "values": [15.6, 10.9, 9.1],
      "ci": [[13.0, 18.2], [9.3, 12.4], [8.0, 10.1]]  // confidence intervals
    },
    {
      "domain": "Sex",
      "labels": ["Male", "Female"],
      "values": [10.3, 12.3],
      "ci": [[8.9, 11.8], [11.0, 13.6]]
    },
    {
      "domain": "Race/Ethnicity",
      "labels": ["White Non-Hisp", "Black Non-Hisp", "Hispanic", 'Other Non-Hisp'],
      "values": [11.0, 12.7, 11.0, 13.6],
      "ci": [[9.8, 12.1], [9.6, 15.9], [8.4, 13.7], [9.2, 18.0]]
    },
    {
      "domain": "Income",
      "labels": ["<$35,000", "$35,000-$74,999", "$75,000+"],
      "values": [18.4, 10.9, 7.4],
      "ci": [[16.0, 20.8], [8.9, 12.8], [6.0, 8.8]]
    },
    {
      "domain": "Insurance",
      "labels": ["Insured", "Not Insured"],
      "values": [11.8, 7.0],
      "ci": [[10.7, 12.8], [4.5, 9.5]]
    },
    {
      "domain": "Disability",
      "labels": ["Disabled", "Non-disabled"],
      "values": [28.7, 6.6],
      "ci": [[25.7, 31.7], [5.7, 7.5]]
    },
    {
      "domain": "Education",
      "labels": ["High School or Less", "More than High School"],
      "values": [14.5, 9.5],
      "ci": [[12.6, 16.3], [8.4, 10.6]]
    },
  ]

  return (
    <section className="mt5 mb4 cf">
      <h3 className="f3">Poor Mental Health</h3>

      <div className="w-100 w-third-l cf fl-l">
        <p className="f4 mt0 measure">
          The bar chart shows the proportion of adults who reported <b>14 or more
          days of poor mental health during the past 30 days</b> in Connecticut,
          as reported by the 2019 Behavioral Risk Factor Survey.
        </p>

        <p className="f4 measure">
          The highest value for each breakdown is highlighted.
          According to the survey, younger people, people on lower incomes,
          people with a disability, and those with only a high school diploma (or less)
          were more likely to report poor mental health.
        </p>

        <p className="f6 black-50 mt0 measure">
          Hover over bars to see 95% confidence intervals, which can be wide for some
          demographic groups. Source: 2019 Connecticut Behavioral Risk Factor
          Survey. <a href="https://portal.ct.gov/-/media/Departments-and-Agencies/DPH/BRFSS/CTBRFSS2019_Summary_Tables.pdf" target="_blank" rel="noopener noreferrer" className="link dim green">
            Explore other summary tables
          </a>.
        </p>
      </div>

      <div className="w-100 w-two-thirds-l cf fl-l">
        {
          charts.map(function(chart) {
            return getBRFSSBarChart( chart.domain, chart.labels, chart.values, chart.ci, "rgba(248,187,45,1)" )
          })
        }
      </div>
    </section>
  )
}