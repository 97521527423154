import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getTopNString } from "./utils.js"
import _ from "lodash"

export default props => {

  const q = useStaticQuery(graphql`
    query HHIncome {
      allAcsMedianHhIncomeCsv {
        nodes {
          Geo
          MedianHHIncome
          Region
        }
      }
    }
  `)

  // Get town data and calculate % elderly and % youth for town to get min/max
  const townData = q.allAcsMedianHhIncomeCsv.nodes.filter(x => x.Region === props.region)

  const min = _.minBy(townData, (o) => parseInt(o.MedianHHIncome))
  const max = _.maxBy(townData, (o) => parseInt(o.MedianHHIncome))

  const ctIncome = parseInt(q.allAcsMedianHhIncomeCsv.nodes.filter(x => x.Geo === 'Connecticut')[0].MedianHHIncome)
  const lessThanCt = townData.filter(o => parseInt(o.MedianHHIncome) < ctIncome).length
  const moreThanCt = townData.filter(o => parseInt(o.MedianHHIncome) > ctIncome).length

  return (
    <section className="cf">

      <div className="w-100 fl f4 pb3 pr3">
        <p className="mt0 measure-wide">
          Median household income in {props.regionName} varies from
          ${ parseInt(min.MedianHHIncome).toLocaleString() } in {min.Geo} to
          ${ parseInt(max.MedianHHIncome).toLocaleString() } in {max.Geo}.
        </p>

        <p className="measure-wide">
          Of {townData.length} towns in the region, {lessThanCt} have median household income 
          lower than Connecticut's median (${ctIncome.toLocaleString()}), {moreThanCt}&mdash;higher.
        </p>

        <p className="measure-wide">
          Some of the richest towns are {getTopNString(townData, {prop: 'MedianHHIncome', n: 4, prefix: '$', skip: 1})}.
          Towns with the lowest median household income
          are { getTopNString(townData, {prop: 'MedianHHIncome', n: 3, prefix: '$', min: true}) }.
        </p>
      </div>

      <div className="w-100">
        <iframe
          title="Median household income by town"
          className="w-100 bw0"
          style={{'height': '500px'}}
          src={"https://ct-data-collaborative.github.io/cpes-data-processing/maps/acs-median-hh-income/" + props.regionNum + '.html'}
        ></iframe>
      </div>

      <p className="f6 i">Source: 2021 American Community Survey, 5-year estimates</p>

    </section>
  )
}