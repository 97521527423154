import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import _ from "lodash"
import { Helmet } from "react-helmet"

import PopAgeChart from "../components/charts/acs-pop-by-age"
import PopRaceChart from "../components/charts/acs-pop-by-race"
import MedianHHIncome from "../components/charts/acs-median-hh-income"
import DisengagedYouth from "../components/charts/acs-disengaged-youth"
import AlcoholSubstanceUseDisorder from "../components/charts/alcohol-substance-use-disorder"
import NeedingButNotReceivingTreatment from "../components/charts/needing-but-not-receiving-treatment"
import ReceivedMH from "../components/charts/received-mh"
import DUICrashes from "../components/charts/dui-crashes"
import DrugDeaths from "../components/charts/drug-deaths"
import DrugDeathsLine from "../components/charts/drug-deaths-line"
import DMHASAdmissions from "../components/charts/dmhas-admissions"
import Suicides from "../components/charts/suicides"
import PoorMentalHealth from "../components/charts/poor-mental-health"
import DepressiveDisorder from "../components/charts/depressive-disorder"
import NsduhRisk from "../components/charts/nsduh-risk"
import NsduhSeriousThoughtsSuicide from "../components/charts/nsduh-serious-thoughts-suicide"
import CrsConcernSubstance from "../components/charts/crs-concern-substance"
import CrsConcernMentalHealth from "../components/charts/crs-concern-mental-health"
import RankingOfPriorities from "../components/charts/ranking-of-priorities"


import Region1 from "../images/1.svg"
import Region2 from "../images/2.svg"
import Region3 from "../images/3.svg"
import Region4 from "../images/4.svg"
import Region5 from "../images/5.svg"

const reg2Image = {
  'Region 1': Region1,
  'Region 2': Region2,
  'Region 3': Region3,
  'Region 4': Region4,
  'Region 5': Region5,
}

const Story = props => {

  const data = props.pageContext;

  const region = data.region;
  const regionName = data.regionName;
  const towns = data.towns.map(x => x.Town);
  const regionNum = region.split(' ')[1];
  const nTowns = data.towns.length;
  const communityTypes = _.groupBy(data.towns, 'Community_Type');
  const nCommunityTypes = Object.keys(communityTypes).length;

  return (
    <Layout>

      <Helmet>
        <title> {regionName} - DMHAS Regional Data Stories </title>
      </Helmet>

      <p className="measure-wide">
        These data stories provide public data aggregated by the DMHAS regions.
        The following data topics are covered in these stories:
        demographics; income; select educational indicators; substance use; and mental health.
        The stories are intended to give a broad overview of the makeup of our communities in
        Connecticut and information to assist with the regional prioritization process.
      </p>

      <p className="bg-near-white pa2 f4 br2 o-90" style={{position: 'sticky', top: '0px', zIndex: 9999}}>
        Jump to
        <a href="#top" className="link black dim dib pl3">&uarr; Top</a>
        <a href="#age" className="link green dim dib pl3">Age</a>
        <a href="#race" className="link green dim dib pl3">Race &amp; Ethnicity</a>
        <a href="#income" className="link green dim dib pl3">Income</a>
        <a href="#schools" className="link green dim dib pl3">Schools</a>
        <a href="#substance-use" className="link green dim dib pl3">Substance Use</a>
        <a href="#mental-health" className="link green dim dib pl3">Mental Health</a>
      </p>

      <div className="fl pr5">
        <h1 className="f1 mb2" id="top"> {regionName} </h1>
        <Link to="/" className="black i dim no-underline"> &larr; Choose a different region </Link>
      </div>


      <img src={reg2Image[data.region]} className="w5 fl" alt="Region map" />

      <div className="cf"></div>

      <p className="f4">
        <b>DMHAS {regionName}</b> ({region}) comprises {nTowns} towns that can be grouped
        into {nCommunityTypes} community types.
      </p>

      <div className="cf">
        {Object.keys(communityTypes).map(comm => 
          <ul key={comm} className="list f4 fl pl0 pr4">
            <li className="fw6">{comm} ({communityTypes[comm].length})</li>
            { communityTypes[comm].map(x => <li key={x.Town} className="black-70">{x.Town}</li>) }
          </ul>
        )}

      </div>

      <h2 className="f2 bb bw2 b--near-white mb0 pb0">Who Lives Here?</h2>

      <h3 className="f3 pt5 mt0" id="age">Population by Age</h3>
      <PopAgeChart region={region} towns={towns} regionName={regionName} />

      <h3 className="f3 mt0 pt5" id="race">Population by Race and Hispanic Ethnicity</h3>
      <PopRaceChart region={region} towns={towns} regionName={regionName} />
      
      <div className="pa3 mt4" id="income">
        <h3 className="f3">Median Household Income</h3>
        <MedianHHIncome region={region} regionNum={regionNum} regionName={regionName}  />
      </div>

      <h2 className="f2 bb bw2 b--near-white mt0 pt5" id="schools">Schools</h2>

      <p className="measure-wide f4">
        Below we will look at chronic absenteeism and disengaged youth, which are known school based factors
        that are associated with substance use in youth.
      </p>

      <h3 className="f3">Chronic Absenteeism</h3>
      <p className="mt0 measure-wide f4">
        Chronically absent students are those who miss 10% or more of the total number
        of days enrolled in the school year, <b>for any reason</b>.
        Pre-K students are excluded from the count.
      </p>

      <p className="f4 measure-wide">
        The maps below show the percentage of students considered chronically
        absent by school district within {region}.
        Data is broken down by race and Hispanic ethnicity,
        and is not available for all districts.
      </p>
      <div className="cf mb4">
        <div className="fl w-100 w-50-l pa1">
          <h4 className="f4 mt0">By school district</h4>
          <iframe
            title="Chronic Absenteeism by School District"
            className="w-100 bw0"
            style={{'height': '400px'}}
            src={"https://ct-data-collaborative.github.io/cpes-data-processing/maps/edsight-absenteeism/" + regionNum + ".html"}
          ></iframe>
        </div>

        <div className="fl w-100 w-50-l pa1">
          <h4 className="f4 mt0">By regional school district</h4>
          <iframe
            title="Chronic Absenteeism by School District"
            className="w-100 bw0"
            style={{'height': '400px'}}
            src={"https://ct-data-collaborative.github.io/cpes-data-processing/maps/edsight-absenteeism/" + regionNum + "-regional.html"}
          ></iframe>
        </div>

        <p className="f6 i">Source: CT Department of Education, 2021-2022</p>
      </div>

      <DisengagedYouth region={region} towns={towns} regionName={regionName} />

      {/*
      <section className="mt3 ph3 pv2 bg-washed-yellow cf">
        <h3 className="f3">Bullying</h3>
        <div className="f4 mt0 fl w-100 w-50-l">
          <p className="mt0 measure">
            According to Section 10-222d of the Connecticut General Statutes, <b>bullying</b> is
            a repeated communication or a physical act or gesture that:
          </p>

          <ul className="pr2">
            <li>causes physical or emotional harm to a student or their property,</li>
            <li>places a student in reasonable fear of self-harm,</li>
            <li>creates a hostile environment at school for a student,</li>
            <li>infringes on the rights of a student at school,</li>
            <li>or substantially disrupts the education process.</li>
          </ul>

          <p className="f5 measure">
            The map shows the total number of bullying incidents by school district in {region}.
            In some school districts with low incident counts, the data are not available
            due to suppression for privacy reasons.
          </p>
        </div>

        <div className="w-100 w-50-l fl">
          <iframe
            title="Bullying by School District"
            className="w-100 bw0"
            style={{'height': '400px'}}
            src={"https://ct-data-collaborative.github.io/cpes-data-processing/maps/edsight-bullying/" + regionNum + ".html"}
          ></iframe>
        </div>
      </section> */}


      <h3 className="f3">Incidents Involving Drugs, Alcohol, and Tobacco</h3>
      <p className="mt0 measure-wide f4">
        When school incidents get reported, they are classified as one or two
        of 100+ incident types. The maps below show counts of what is broadly
        classified as <i>Drugs, Alcohol, Tobacco</i> incidents by the CT Department of Education.
      </p>

      <div className="cf mb4">
        <div className="fl w-100 w-50-l pa1">
          <h4 className="f4 mt0">By school district</h4>
          <iframe
            title="Drug, Alcohol, Tobacco Incidents by Regional School District"
            className="w-100 bw0"
            style={{'height': '400px'}}
            src={"https://ct-data-collaborative.github.io/cpes-data-processing/maps/edsight-substances/" + regionNum + ".html"}
          ></iframe>
        </div>

        <div className="fl w-100 w-50-l pa1">
          <h4 className="f4 mt0">By regional school district</h4>
          <iframe
            title="Drug, Alcohol, Tobacco Incidents by Regional School District"
            className="w-100 bw0"
            style={{'height': '400px'}}
            src={"https://ct-data-collaborative.github.io/cpes-data-processing/maps/edsight-substances/" + regionNum + "-regional.html"}
          ></iframe>
        </div>
        <p className="f6 i">Source: CT Department of Education, 2021-2022</p>
      </div>


      <h2 className="f2 bb bw2 b--near-white mt0 pt5" id="substance-use">Substance Use</h2>

      <p className="f4 measure-wide">
        The four maps below show the percentage of population who used an indicated
        substance in the past month, by age group. Data is at region level, and is not available for
        individual towns.
      </p>

      <p className="measure-wide">
        Use radio buttons in each map to switch between age groups. Note that switching
        age groups in one map does not affect age groups in the other maps. Legends
        are scaled differently for each map to better represent variability among regions.
      </p>

      <p className="measure-wide f6 light-red">
        Substate estimates for 2018-2020 are not available due to COVID-19.
        Data were collected online in 2020 and analysis has shown that the different
        collection cause a mode effect and therefore data cannot be averaged across the years.
      </p>

      <section className="cf">
        <div className="w-100 w-50-l fl pv3 pr2">
          <h4 className="f3 mv1">Alcohol</h4>
          <iframe
              title="Alcohol Use in the Past Month"
              className="w-100 bw0"
              style={{'height': '400px'}}
              src={"https://ct-data-collaborative.github.io/cpes-data-processing/maps/nsduh/nsduh-alcohol-past-month.html"}
          ></iframe>
        </div>

        <div className="w-100 w-50-l fl pv3 pr2">
          <h4 className="f3 mv1">Marijuana</h4>
          <iframe
            title="Marijuana Use in the Past Month"
            className="w-100 bw0"
            style={{'height': '400px'}}
            src={"https://ct-data-collaborative.github.io/cpes-data-processing/maps/nsduh/nsduh-marijuana-past-month.html"}
          ></iframe>
        </div>

        <div className="w-100 w-50-l fl pv3 pr2">
          <h4 className="f3 mv1">Tobacco</h4>
          <iframe
            title="Tobacco Use in the Past Month"
            className="w-100 bw0"
            style={{'height': '400px'}}
            src={"https://ct-data-collaborative.github.io/cpes-data-processing/maps/nsduh/nsduh-tobacco-past-month.html"}
          ></iframe>
        </div>

        <div className="w-100 w-50-l fl pv3 pr2">
          <h4 className="f3 mv1">Cigarette</h4>
          <iframe
            title="Cigarette Use in the Past Month"
            className="w-100 bw0"
            style={{'height': '400px'}}
            src={"https://ct-data-collaborative.github.io/cpes-data-processing/maps/nsduh/nsduh-cigarette-past-month.html"}
          ></iframe>
        </div>

        <p className="f6 i">Source: 2018<span className="red">*</span> National Survey on Drug Use and Health (NSDUH)</p>


        <div className="pv3 mt4 cf">
          <hr className="bg-near-white h1 bw0" />
          <h3 className="f3">Alcohol and Substance Use Disorder</h3>

          <div className="w-100 w-third-l fl cf">
            <p className="measure-narrow f4">
              This bar chart shows the percentage of individuals aged 12 and older
              who met the criteria for alcohol dependence or abuse (yellow), or
              met the criteria for illicit drug or alcohol dependence or abuse
              (green) in the past year, as reported in the 2018<span className="red">*</span> NSDUH survey.
            </p>

            <p className="black-50 measure-narrow">
              The values are annual averages based on 2016, 2017, and 2018 NSDUHs.
              Hover over bars to see exact percentages.
              Click legend items to hide or show the series.
            </p>
          </div>

          <div className="w-100 w-two-thirds-l fl cf">
            <AlcoholSubstanceUseDisorder region={region} regionNum={regionNum} regionName={regionName} />
          </div>
        </div>

        <RankingOfPriorities region={region} regionNum={regionNum} regionName={regionName} />

        <DUICrashes region={region} regionNum={regionNum} regionName={regionName} />

        <DrugDeaths region={region} regionNum={regionNum} regionName={regionName} />
        <DrugDeathsLine region={region} regionNum={regionNum} regionName={regionName} />


        <div className="pv3 mt4 cf">
          <h3 className="f3">Needing But Not Receiving Treatment</h3>

          <div className="w-100 w-third-l fl pr2 cf">
            <p className="measure-narrow f4">
              The bar chart shows the percentage of individuals aged 12 and older who needed
              but did not receive treatment at a special facility for alcohol use,
              illicit drug use, and substance use in the past year,
              as reported in the 2018<span className="red">*</span> NSDUH survey.
            </p>
            
            <p className="measure-narrow">
              In all regions, roughly between 2% to 3% of people in need do not receive treatment
              for illicit drugs, between 5% to 6% for alcohol abuse, and
              between 7% to 8% for substance abuse.
            </p>

            <p className="black-50 measure-narrow">
              The values are annual averages based on 2016, 2017, and 2018 NSDUHs.
              Hover over bars to see exact percentages.
              Click legend items to hide or show series.
            </p>
          </div>

          <div className="w-100 w-two-thirds-l fl cf">
            <NeedingButNotReceivingTreatment region={region} regionNum={regionNum} regionName={regionName} />
            <p className="f6 i">Source: 2018<span className="light-red">*</span> National Survey on Drug Use and Health (NSDUH)</p>
          </div>
        </div>


        <div className="pv3 mt4 cf">
          <h3 className="f3">Perception of Great Risk</h3>

          <div className="w-100 w-third-l fl pr2 cf">
            <p className="measure-narrow f4">
              The National Survey on Drug Use and Health asks questions
              about risk perception for the following substances:
            </p>

            <ul className="measure">
              <li>Marijuana (smoking once a month)</li>
              <li>Cocaine (using once a month)</li>
              <li>Heroin (trying once or twice)</li>
              <li>Alcohol (five or more drinks once or twice a week)</li>
              <li>Cigarette (smoking one or more packs per day)</li>
            </ul>
            
            <p className="measure-narrow">
              The bars indicate the percentage of respondents with great risk perception
              by age group and substance type <b>in {regionName} region</b>.
              Red bars indicate state values.
            </p>

            <p className="black-50 measure-narrow">
              The values are annual averages based on 2016, 2017, and 2018 NSDUHs.
              Hover over bars to see exact percentages.
            </p>
          </div>

          <div className="w-100 w-two-thirds-l fl cf">
          <NsduhRisk regionName={regionName} />
            <p className="f6 i">Source: 2016-2018<span className="light-red">*</span> National Surveys on Drug Use and Health (NSDUH)</p>
          </div>
        </div>

        <CrsConcernSubstance regionName={regionName} region={region} />

      </section>



      {/*  MENTAL HEALTH */ }
      <h2 className="f2 bb bw2 b--near-white mt0 pt5" id="mental-health">
        Mental Health
      </h2>

      <p className="f4 measure-wide">
        The two maps below show the percentage of population who suffered from a mental
        illness in the past year, by age group, in 5 regions.
      </p>

      <p className="measure-wide">
        Use radio buttons in each map to switch between age groups. Note that switching
        age groups in one map does not affect age groups in the other map. Legends
        are scaled differently for each map to better represent variability between regions.
      </p>

      <p className="measure-wide f6 light-red">
        *Substate estimates for 2018-2020 are not available due to COVID-19.
        Data were collected online in 2020 and analysis has shown that the different
        collection cause a mode effect and therefore data cannot be averaged across the years.
      </p>

      <section className="cf">
        <div className="w-100 w-50-l fl pv3 pr2">
          <h3 className="f3"> Any Mental Illness (past year)</h3>
          <iframe
              title="Any Mental Illness in the Past Year"
              className="w-100 bw0"
              style={{'height': '400px'}}
              src={"https://ct-data-collaborative.github.io/cpes-data-processing/maps/nsduh/nsduh-any-mental-illness.html"}
          ></iframe>
        </div>

        <div className="w-100 w-50-l fl pv3 pr2">
          <h3 className="f3"> Serious Mental Illness (past year)</h3>
          <iframe
            title="Serious Mental Illness in the Past Year"
            className="w-100 bw0"
            style={{'height': '400px'}}
            src={"https://ct-data-collaborative.github.io/cpes-data-processing/maps/nsduh/nsduh-serious-mental-illness.html"}
          ></iframe>
        </div>

        <p className="f6 i">Source: 2018<span className="light-red">*</span> National Survey on Drug Use and Health (NSDUH)</p>

        <PoorMentalHealth />
        <DepressiveDisorder />

        <div className="pv3 mt4 cf">
          <h3 className="f3">Received Mental Health Services</h3>

          <div className="w-100 w-third-l fl cf pr2">
            <p className="measure-narrow f4">
              The bar chart shows the percentage of adults (aged 18 and older) who received
              mental health services in the past year, according to the 2018 NSDUH<span className="red">*</span> survey.
            </p>

            <p className="black-50 measure-narrow">
              The values are annual averages based on 2016, 2017, and 2018 NSDUHs.
              Hover over bars to see exact percentages.
            </p>
          </div>

          <div className="w-100 w-two-thirds-l fl cf">
            <ReceivedMH region={region} regionNum={regionNum} regionName={regionName} />
            <p className="f6 i">Source: 2018<span className="light-red">*</span> National Survey on Drug Use and Health (NSDUH)</p>
          </div>
        </div>


        <div className="pv3 mt4 cf">
          <h3 className="f3">Serious Thoughts of Suicide</h3>

          <div className="w-100 w-third-l fl pr2 cf">
            <p className="measure-narrow f4">
              The National Survey on Drug Use and Health asks respondents
              if they had serious thoughts of suicide in the past 12 months.
              The bars indicate the percentage of respondents who had serious
              suicidal thoughts <b>in {regionName} region</b> and the state,
              by age group.
            </p>

            <p className="black-50 measure-narrow">
              The values are annual averages based on 2016, 2017, and 2018 NSDUHs.
            </p>
          </div>

          <div className="w-100 w-two-thirds-l fl cf">
            <NsduhSeriousThoughtsSuicide regionName={regionName} />
            <p className="f6 i">Source: 2016-2018<span className="light-red">*</span> National Surveys on Drug Use and Health (NSDUH)</p>
          </div>
        </div>


        <div className="pv3 mt4 cf">
          <h3 className="f3">Suicide Rates</h3>

          <div className="w-100 w-third-l fl cf pr2">
            <p className="measure-narrow f4">
              The bar chart shows number of suicides and suicide rates (per 100,000 residents)
              for each DMHAS region in Connecticut, between 2015-2019.
            </p>

            <div className="pa3 bg-washed-red measure-narrow f5">
              Statewide, between 2015-2019, of those who died of suicide,

              <ul className="mv1">
                <li>74% were male and 26% were female;</li>
                <li>92.0% were white, 5.2% were Black, and 1.8% were Asian;</li>
                <li>94% were non-Hispnaic; and 6% were Hispanic.</li>
              </ul>

            </div>

            <p className="black-50 measure-narrow">
              Counts and rates are based on the city of residence; 104 cases
              had a missing residence city. Note two different scales for y-axes.
              Hover over bars to see exact values.
            </p>
          </div>

          <div className="w-100 w-two-thirds-l fl cf">
            <Suicides regionName={regionName} />
            <p className="f6 i">Source: DPH VDRS, 2015-2019</p>
          </div>
        </div>


        <DMHASAdmissions region={region} regionNum={regionNum} regionName={regionName} />

        <CrsConcernMentalHealth regionName={regionName} region={region} />

      </section>

    </Layout>
  )

}

export default Story