import React, {useState} from "react"
import { useStaticQuery, graphql } from "gatsby"
import { HorizontalBar } from "react-chartjs-2"

export default props => {

  const q = useStaticQuery(graphql`
    query CrsConcernMentalHealth {
      allCrsConcernSubstanceCsv {
        nodes {
          Geo
          _12_17_Depression
          _12_17_Anxiety
          _12_17_Trauma
          _12_17_Suicide
          
          _18_25_Depression
          _18_25_Anxiety
          _18_25_Trauma
          _18_25_Suicide

          _26_65_Depression
          _26_65_Anxiety
          _26_65_Trauma
          _26_65_Suicide

          _66__Depression
          _66__Anxiety
          _66__Trauma
          _66__Suicide
        }
      }
    }
  `)

  const [regionData, setRegionData] = useState(
    q.allCrsConcernSubstanceCsv.nodes.filter(
      x => x.Geo === props.region)[0] // default view is region
  )

  const ages = ['12_17', '18_25', '26_65', '66_'];
  const agesFormatted = ['12 to 17', '18 to 25', '26 to 65', '66+'];

  const substances = ['Depression', 'Anxiety', 'Trauma', 'Suicide'];
  const substancesFormatted = ['Depression', 'Anxiety', 'Trauma', 'Suicide'];

  const sub2color = {
    'Depression': 'rgba(112,173,70,1)',
    'Anxiety': 'rgb(68,114,197)',
    'Trauma': 'rgba(165,165,165,1)',
    'Suicide': 'rgb(255,255,0)'
  }

  const datasets = substances.map(function(sub, i) {
    return {
      label: substancesFormatted[i],
      backgroundColor: sub2color[sub],
      data: ages.map(function(ageGroup) { return regionData['_' + ageGroup + '_' + sub] })
    }
  });

  const switcher = function(geo) {
    return <label className="f4 pr4 dim">
      <input
        type="radio"
        defaultChecked={geo !== 'Connecticut'}
        name="crs-concern-mental-health-region"
        onClick={() => { setRegionData(q.allCrsConcernSubstanceCsv.nodes.filter(
            x => x.Geo === geo)[0])
        }}
        value={geo}
      /> {geo === 'Connecticut' ? geo : props.regionName}
    </label>
  }

  return (
      <section>
        <div className="w-100 cf">
          <h3 className="f3">Mental Health Issues of Greatest Concern</h3>

          <p className="measure-wide f4 mt2">
            Below is a bar chart showing mental health issues of greatest concern by age group
            in {props.regionName} region and Connecticut,
            according to the state's 2020 Community Readiness Survey.
          </p>

          <p className="measure-wide f5 black-50">
            Toggle between {props.regionName} and the state to see the differences.
            Hover over bars to see exact percentages.
            Click legend items to add or remove individual substances from the chart.
          </p>

          <div className="pa3 ba dib bw1 b--black-20 br2 mb3">
            {switcher( props.region )}
            {switcher( 'Connecticut' )}
          </div>

        </div>

        <div className="relative">
          <HorizontalBar height={400} data={
            {
              labels: agesFormatted,
              datasets: datasets
            }
          } options={
            {
              legend: {
                display: true
              },
              scales: {
                xAxes: [
                  {
                    stacked: true,
                    gridLines: {
                      display: true,
                      drawBorder: false,
                    },
                    ticks: {
                      max: 100,
                      userCallback: function(value, index, values) {
                        return value.toLocaleString()
                      },
                      beginAtZero: true,
                    }
                  }
                ],
                yAxes: [
                  { 
                    display: true,
                    stacked: true,
                    gridLines: {
                      display: false,
                      drawBorder: false,
                    },
                  }
                ]
              },
              tooltips: {
                displayColors: true,
                callbacks: {
                  
                  label: function(tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].label
                        + ' is of Greatest Concern: ' + tooltipItem.value + '%'
                  },
                }
              },
              plugins: {
                datalabels: {
                  display: true,
                  align: 'right',
                  anchor: 'start',
                color: function(value) { return value.dataset.label === 'Suicide' ? 'black' : 'white' },
                  formatter: function(value, context) {
                    return value >= 3 ? Math.round(value) + '%' : '';
                  }
                }
              },
              maintainAspectRatio: false
            }
          } />
          
        </div>
      </section>
  )
}