import React, {useState} from "react"
import { useStaticQuery, graphql } from "gatsby"
import { HorizontalBar } from "react-chartjs-2"

export default props => {

  const q = useStaticQuery(graphql`
    query CrsConcernSubstance {
      allCrsConcernSubstanceCsv {
        nodes {
          Geo
          _12_17_Alcohol
          _12_17_Tobacco_Cigarettes
          _12_17_Vaping_ENDS
          _12_17_Marijuana_Hashish_THC
          _12_17_Cocaine_Crack
          _12_17_Heroin_Fentanyl
          _12_17_Prescription_drugs
          _12_17_Problem_Gambling
          
          _18_25_Alcohol
          _18_25_Tobacco_Cigarettes
          _18_25_Vaping_ENDS
          _18_25_Marijuana_Hashish_THC
          _18_25_Cocaine_Crack
          _18_25_Heroin_Fentanyl
          _18_25_Prescription_drugs
          _18_25_Problem_Gambling

          _26_65_Alcohol
          _26_65_Tobacco_Cigarettes
          _26_65_Vaping_ENDS
          _26_65_Marijuana_Hashish_THC
          _26_65_Cocaine_Crack
          _26_65_Heroin_Fentanyl
          _26_65_Prescription_drugs
          _26_65_Problem_Gambling

          _66__Alcohol
          _66__Tobacco_Cigarettes
          _66__Vaping_ENDS
          _66__Marijuana_Hashish_THC
          _66__Cocaine_Crack
          _66__Heroin_Fentanyl
          _66__Prescription_drugs
          _66__Problem_Gambling
        }
      }
    }
  `)

  const [regionData, setRegionData] = useState(
    q.allCrsConcernSubstanceCsv.nodes.filter(
      x => x.Geo === props.region)[0] // default view is region
  )

  const ages = ['12_17', '18_25', '26_65', '66_'];
  const agesFormatted = ['12 to 17', '18 to 25', '26 to 65', '66+'];

  const substances = ['Alcohol', 'Tobacco_Cigarettes', 'Vaping_ENDS', 'Marijuana_Hashish_THC',
    'Cocaine_Crack', 'Heroin_Fentanyl', 'Prescription_drugs', 'Problem_Gambling'];
  const substancesFormatted = ['Alcohol', 'Tobacco/Cigarettes', 'Vaping/ENDS', 'Marijuana, Hashish or THC',
    'Cocaine or Crack', 'Heroin or Fentanyl', 'Prescription drugs', 'Problem Gambling'];

  const sub2color = {
    'Alcohol': 'rgba(112,173,70,1)',
    'Tobacco_Cigarettes': 'rgb(68,114,197)',
    'Vaping_ENDS': 'rgb(155,155,155)',
    'Marijuana_Hashish_THC': 'rgb(255,255,0)',
    'Cocaine_Crack': 'rgb(255,184,0)',
    'Heroin_Fentanyl': 'rgb(101,42,150)',
    'Prescription_drugs': 'rgb(255,0,0)',
    'Problem_Gambling': 'rgb(226,226,226)',
  }

  const datasets = substances.map(function(sub, i) {
    return {
      label: substancesFormatted[i],
      backgroundColor: sub2color[sub],
      data: ages.map(function(ageGroup) { return regionData['_' + ageGroup + '_' + sub] })
    }
  });

  const switcher = function(geo) {
    return <label className="f4 pr4 dim">
      <input
        type="radio"
        defaultChecked={geo !== 'Connecticut'}
        name="crs-concern-substance-region"
        onClick={() => { setRegionData(q.allCrsConcernSubstanceCsv.nodes.filter(
            x => x.Geo === geo)[0])
        }}
        value={geo}
      /> {geo === 'Connecticut' ? geo : props.regionName}
    </label>
  }

  return (
      <section>
        <div className="w-100 cf">
          <h3 className="f3">Substances of Greatest Concern</h3>

          <p className="measure-wide f4 mt2">
            Below is a bar chart showing substances of greatest concern by age group,
            according to Connecticut's 2020 Community Readiness Survey.
          </p>

          <p className="measure-wide f5 black-50">
            Toggle between {props.regionName} and the state to see the differences.
            Hover over bars to see exact percentages.
            Click legend items to add or remove individual substances from the chart.
          </p>

          <div className="pa3 ba dib bw1 b--black-20 br2 mb3">
            {switcher( props.region )}
            {switcher( 'Connecticut' )}
          </div>

        </div>

        <div className="relative">
          <HorizontalBar height={400} data={
            {
              labels: agesFormatted,
              datasets: datasets
            }
          } options={
            {
              legend: {
                display: true
              },
              scales: {
                xAxes: [
                  {
                    stacked: true,
                    gridLines: {
                      display: true,
                      drawBorder: false,
                    },
                    ticks: {
                      max: 100,
                      userCallback: function(value, index, values) {
                        return value.toLocaleString()
                      },
                      beginAtZero: true,
                    }
                  }
                ],
                yAxes: [
                  { 
                    display: true,
                    stacked: true,
                    gridLines: {
                      display: false,
                      drawBorder: false,
                    },
                  }
                ]
              },
              tooltips: {
                displayColors: true,
                callbacks: {
                  
                  label: function(tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].label
                        + ' is of Greatest Concern: ' + tooltipItem.value + '%'
                  },
                }
              },
              plugins: {
                datalabels: {
                  display: true,
                  align: 'right',
                  anchor: 'start',
                  color: function(value) { return value.dataset.label === 'Marijuana, Hashish or THC' ? 'black' : 'white' },
                  formatter: function(value, context) {
                    return value > 5 ? Math.round(value) + '%' : '';
                  }
                }
              },
              maintainAspectRatio: false
            }
          } />
          
        </div>
      </section>
  )
}