import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { HorizontalBar } from "react-chartjs-2"

export default props => {

  const q = useStaticQuery(graphql`
    query NsduhSuicide {
      allNsduhSeriousThoughtsSuicideCsv {
        nodes {
          Geo
          _18_25_Estimate
          _18_or_Older_Estimate
          _26_or_Older_Estimate
        }
      }
    }
  `)

  const regionData = q.allNsduhSeriousThoughtsSuicideCsv.nodes.filter(
    x => x.Geo === props.regionName
  );

  const stateData = q.allNsduhSeriousThoughtsSuicideCsv.nodes.filter(
    x => x.Geo === 'Connecticut'
  );

  const labels = ['_18_25_Estimate', '_18_or_Older_Estimate', '_26_or_Older_Estimate'];
  const labelsFormatted = ['18 to 25', '18 or Older', '26 and Older']

  const stateColor = '#bbbbbb';

  const datasets = [
    {
      label: props.regionName,
      backgroundColor: 'blue',
      data: labels.map(function(ageGroup) { return regionData[0][ageGroup] })
    },

    {
      label: 'State',
      backgroundColor: stateColor,
      data: labels.map(function(ageGroup) { return stateData[0][ageGroup] })
    }
  ]

  return (
      <div className="relative">
        <HorizontalBar height={250} data={
          {
            labels: labelsFormatted,
            datasets: datasets
          }
        } options={
          {
            legend: {
              display: true
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: true,
                    drawBorder: false,
                  },
                  ticks: {
                    userCallback: function(value, index, values) {
                      return value.toLocaleString()
                    },
                    beginAtZero: true,
                  }
                }
              ],
              yAxes: [
                { 
                  display: true,
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  },
                }
              ]
            },
            tooltips: {
              displayColors: true,
              callbacks: {
                label: function(tooltipItem, data) {
                  return data.datasets[tooltipItem.datasetIndex].label
                      + ': ' + tooltipItem.value + '%'
                }
              }
            },
            plugins: {
              datalabels: {
                display: true,
                align: 'left',
                anchor: 'end',
                color: 'white',
                formatter: function(value, context) {
                  return value + '%'
                }
              }
            },
            maintainAspectRatio: false
          }
        } />

        
      </div>
  )
}