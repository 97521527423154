import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import _ from "lodash"
import { HorizontalBar } from "react-chartjs-2"

export default props => {

  const q = useStaticQuery(graphql`
    query DisengagedYouth {
      allAcsDisengagedYouthByGenderCsv {
        nodes {
          Geo
          MaleYouth
          FemaleYouth
          MaleYouthDisengaged
          FemaleYouthDisengaged
          MaleYouthDisengagedMoe
          FemaleYouthDisengagedMoe
        }
      }
    }
  `)

  // Get town data and calculate % elderly and % youth for town to get min/max
  const townData = q.allAcsDisengagedYouthByGenderCsv.nodes
    .filter(x => props.towns.includes(x.Geo))
    .map(o => {
      o['DisengagedTotal'] = parseInt(o.MaleYouthDisengaged) + parseInt(o.FemaleYouthDisengaged);
      o['MalePercent'] = parseInt(o.MaleYouthDisengaged) / parseInt(o.MaleYouth) * 100;
      o['FemalePercent'] = parseInt(o.FemaleYouthDisengaged) / parseInt(o.FemaleYouth) * 100;
      return o;
  })

  const regionMaleYouth = townData.map(o => parseInt(o.MaleYouth)).reduce((a, b) => a + b, 0)
  const regionMaleYouthDisengaged = townData.map(o => parseInt(o.MaleYouthDisengaged)).reduce((a, b) => a + b, 0)

  const regionFemaleYouth = townData.map(o => parseInt(o.FemaleYouth)).reduce((a, b) => a + b, 0)
  const regionFemaleYouthDisengaged = townData.map(o => parseInt(o.FemaleYouthDisengaged)).reduce((a, b) => a + b, 0)

  const dataToPlot = _.sortBy(townData, 'DisengagedTotal').slice(-6)

  return (
    <section className="cf bg-light-yellow ph2">
      <div className="w-100 w-50-l fl pr2">
        <h3 className="f3">Disengaged Youth</h3>

        <p className="f4 measure-wide">
          Young people who are not enrolled in school and are not employed are considered disengaged.
          In {props.regionName}, {(regionFemaleYouthDisengaged / regionFemaleYouth * 100).toFixed(1)}% of female youth
          (or {regionFemaleYouthDisengaged.toLocaleString()} people)
          and {(regionMaleYouthDisengaged / regionMaleYouth * 100).toFixed(1)}% of male youth
          ({regionMaleYouthDisengaged.toLocaleString()} people) are considered disengaged.
        </p>

        <p>
          The bar chart shows total number (x-axis) and percentage (labels) of disengaged youth by town for both genders.
          Only the 6 towns with the highest number of disengaged youth are shown. Hover over bars to see margins of error,
          which can be high for some (especially smaller) towns.
        </p>
      </div>

      <div className="w-100 w-50-l mt3 fl">
        <HorizontalBar height={200} data={
            {
              labels: dataToPlot.map(x => x.Geo),
              datasets: [
                {
                  label: 'Female',
                  data: dataToPlot.map(x => x.FemaleYouthDisengaged),
                  percents: dataToPlot.map(x => x.FemalePercent),
                  moes: dataToPlot.map(x => x.FemaleYouthDisengagedMoe),
                  backgroundColor: 'silver'
                },
                {
                  label: 'Male',
                  data: dataToPlot.map(x => x.MaleYouthDisengaged),
                  percents: dataToPlot.map(x => x.MalePercent),
                  moes: dataToPlot.map(x => x.MaleYouthDisengagedMoe),
                  backgroundColor: '#f6bb2d'
                }
              ]
            }
          } options={
            {
              legend: {
                display: true,
              },
              scales: {
                xAxes: [
                  { 
                    display: true,
                    
                    
                    gridLines: {
                      display: true,
                      drawBorder: false,
                    },
                    ticks: {
                      max: 425,
                      userCallback: function(value, index, values) {
                        return value.toLocaleString()
                      },
                      beginAtZero: true,
                    }
                  }
                ],
                yAxes: [
                  {
                    gridLines: {
                      display: false,
                      drawBorder: false,
                    },
                    ticks: {
                      beginAtZero: true,
                    }
                  }
                ]
              },
              tooltips: {
                callbacks: {
                  label: function(tooltipItem, data) {
                    return data.datasets[ tooltipItem.datasetIndex ].label
                      + ': ' + tooltipItem.value + ' ± '
                      + data.datasets[tooltipItem.datasetIndex]['moes'][tooltipItem.index] + ' people'
                  },
                }
              },
              plugins: {
                datalabels: {
                  display: true,
                  align: 'end',
                  
                  anchor: 'end',
                  color: 'black',
                  formatter: function(value, data) {
                    return data.dataset.percents[ data.dataIndex ].toFixed(0) + '%';
                  }
                }
              }
            }
          } />
          <p className="f6 i">Source: 2021 American Community Survey, 5-year estimates</p>
      </div>
      
    </section>
  )
}